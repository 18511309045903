import { PageSection, SectionWrapper } from "../global/flexbox";
import styled, { css } from "styled-components";

import { respondTo } from "../../utils/breakpoints";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${respondTo.m`
    flex-direction: row;
    gap: 20px;
    &.space-between {
      justify-content: space-between;
    }
  `}
`

export const Col = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.align-none {
    align-items: none;
    width: 100%;
  }
  &.width-12 {
    width: 50%;
    text-align: center;
  }
  &.50 {
    width: 50%;
  }
  &.align-start {
    gap: 8px;
    align-items: flex-start;
    justify-content: center;
  }
  > * h2 {
    text-align: center !important;
  }
  ${respondTo.m`
    gap: 40px;
    &.small-gap {
      gap: 4px;
    }
  `}
`

export const StyledPageSection = styled(PageSection)`
  background: url(${(props) => props.bubbles_bg || ''}), linear-gradient(90deg, #0F5575 50.34%, rgba(15, 85, 117, 0.00) 100%), url(${(props) => props.gradient_bg_img});
  background-size: contain cover contain; /* To make the background image cover the entire container */
  background-repeat: no-repeat; /* To prevent the image from repeating */
  background-position-x: left, center, right;
  background-position-y: center, center, 30%;
  min-height: 500px;
`;

export const GradientWrapper = styled(SectionWrapper)`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondTo.m`
    flex-direction: row;
  `}
`

export const InnerColumnCss = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
    align-items:center;
    justify-items: space-around;
  &.small-gap {
    gap: 12px;
  }
  ${respondTo.m`
      align-items: flex-start;
    justify-content: center;
  `}
`

export const InnerColumn40 = styled.div`
  ${respondTo.m`
  width: 40%;

`}
  ${InnerColumnCss}
`

export const InnerColumn50 = styled.div`
  ${respondTo.m`
  width: 50%;
  &.hero {
    padding: 40px 0px !important;
    gap: 12px;
  }
`}
  ${InnerColumnCss}
  &.hero-img {
    align-items: flex-end;
    > img {
      width: 100%;
    }
  }
`

export const InnerColumn60 = styled.div`
  ${respondTo.m`
  świdth: 60%;

`}
  ${InnerColumnCss}
`